<template>
  <div>
    <v-dialog v-model="ModalEdit" persistent max-width="50%">
      <v-card>
        <v-card-title> Edit Jabatan </v-card-title>

        <v-divider class="mt-2 mb-5"></v-divider>

        <v-card-text>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="SData.idBKN"
                  label="ID JABATAN BKN"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense v-if="SData.eselon">
              <v-col cols="12" md="12">
                <v-select
                  v-model="SData.eselon.id"
                  label="Formasi Jabatan"
                  :items="refeselon"
                  item-value="id"
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span class="mr-1" v-if="item.jabatanASN">
                      {{ item.jabatanASN }} -
                    </span>
                    {{ item.nama }}
                  </template>

                  <template v-slot:item="{ item }">
                    <span class="mr-1" v-if="item.jabatanASN">
                      {{ item.jabatanASN }} -
                    </span>
                    {{ item.nama }}
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="SData.nama"
                  label="Nama Jabatan"
                  outlined
                  dense
                  rows="2"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="SData.alias"
                  label="Nama Alias"
                  outlined
                  dense
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="SData.bup"
                  label="Batas Usia Pensiun"
                  outlined
                  dense
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="SData.status"
                  label="Status"
                  :items="refstatus"
                  item-value="id"
                  item-text="nama"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="mt-n5"></v-divider>

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn color="gray" @click="closeModal()"> Batal </v-btn>

          <v-btn color="primary" @click="edit()" v-if="LBtn"> Simpan </v-btn>
          <v-btn color="primary" loading v-else> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getReferensi.js";

import SView from "@/store/jabatan/pimpinantinggi/view";
import SEdit from "@/store/jabatan/pimpinantinggi/edit";

export default {
  computed: {
    ModalEdit: {
      get() {
        return SEdit.state.ModalEdit;
      },
      set(value) {
        SEdit.commit("TModal", value);
      },
    },

    SData: {
      get() {
        return SEdit.state.data;
      },

      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async ModalEdit(value) {
      if (value) {
        this.refeselon = await getRef.eselon(this.token);
        this.refstatus = await getRef.status();
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    LBtn: true,

    refeselon: [],
    refstatus: [],
  }),

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    async edit() {
      this.LBtn = false;

      let data = {
        id: this.SData.id,
        bkn_id: this.SData.idBKN,
        eselon_id: this.SData.eselon.id,
        nama_pt: this.SData.nama,
        alias_pt: this.SData.alias,
        bup_pt: this.SData.bup,
        status_pt: this.SData.status,
      };

      const url = process.env.VUE_APP_API + "ref/pimpinantinggi";
      this.axios
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.LBtn = true;
          if (res.data.success) {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("berhasilAlert", true);
            SView.commit("gagalAlert", false);
            SView.commit("success", res.data.success);
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", res.data.success);
          }

          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.LBtn = true;
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.LBtn = true;
          }

          this.closeModal();
        });
    },
    async closeModal() {
      await SView.commit("refreshData", true);
      this.reset();
      this.ModalEdit = false;
    },
  },
};
</script>
